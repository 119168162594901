<template>
  <!-- 공지사항/이벤트 -->
  <article class="contents">
    <div class="notice-area">
      <!-- <div class="tab-nav common-type">
        <ul>
          <li class="on">
            <a>공지사항</a>
          </li>
          <li class="">
            <a @click="$router.push({ name: 'app-event-main' })">이벤트</a>
          </li>
        </ul>
      </div> -->
      <div class="search-wrap">
        <!-- display:flex -->
        <div class="left-col">
          <div class="select-wrap">
            <select v-model="query.searchType">
              <option
                v-for="option in codes.searchType"
                :key="option.code"
                :value="option.code"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="search-bar">
            <div class="text-wrap">
              <input
                v-model="query.keyword"
                type="text"
                placeholder="키워드를 입력하세요."
                class="search-type"
                @keyup.enter="fetchNotices()"
              >
              <button class="btn-search">
                검색
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-contents">
        <div>
          <!-- 공지사항 -->
          <div class="tb-list-type notice-type">
            <table>
              <colgroup>
                <col style="width: 100px">
                <col style="width: 531px">
                <col style="width: 90px">
                <col style="width: 180px">
                <col style="width: 90px">
              </colgroup>
              <thead>
                <tr>
                  <th>순번</th>
                  <th>제목</th>
                  <th>첨부파일</th>
                  <th>작성일</th>
                  <th>조회수</th>
                </tr>
              </thead>
              <tbody>
                <!-- 한 페이지 12줄 씩 -->
                <tr
                  v-for="item in notices"
                  :key="item.id"
                  :class="{ 'notice-fix': (item.isFixed && item.fixedType === 1), 'webinar-fix': (item.isFixed && item.fixedType === 2) }"
                  @click="noticeClick(item)"
                >
                  <td v-if="item.isFixed">
                    <i :class="{ 'ico-notice': item.fixedType === 1, 'ico-notice-webinar': item.fixedType === 2 }" />
                  </td>
                  <td v-else>
                    {{ item.id }}
                  </td>
                  <td>
                    <div class="reply-list-td">
                      <p class="ellipsis-txt">
                        <a>
                          {{ item.title }}
                        </a>
                      </p>
                      <span v-if="item.allowComments && item.commentCount > 0">[{{ item.commentCount }}]</span>
                    </div>
                  </td>
                  <td><i
                    v-if="item.isAttach"
                    class="file-add"
                  /></td>
                  <td>{{ item.createDate | $dateFormatter('YYYY. MM. DD') }}</td>
                  <td>{{ item.viewCnt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :page="currentPage"
            :records="totalRecord"
            :per="pageViewCount"
            @paging="pageUpdate"
          />
        </div>

        <!-- 공지사항 상세보기 -->
        <div
          id="noticeView"
          class="contact-details-view"
          style="display: none"
        >
          <div class="tb-view-type">
            <table>
              <colgroup>
                <col style="width: auto">
                <col style="width: 360px">
              </colgroup>
              <thead>
                <tr>
                  <th>{{ notice.title }}</th>
                  <th>
                    <div class="flex-event-r">
                      <strong>조회수</strong><span>{{ notice.viewCnt }}</span>
                      <i class="bu-line" />
                      <strong>작성일</strong><span>{{ notice.createDateStr }}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="2">
                    <div
                      id="content"
                      v-dompurify-html="notice.contents"
                      class="text-in"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <ul
                      v-for="item in noticeFiles"
                      :key="item.fileId"
                      class="add-list"
                    >
                      <li>
                        <a
                          href="#"
                          @click="downloadFile(item.fileId, item.name)"
                        ><i class="i-file-add" />{{ item.name }}</a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex-r">
            <button
              id="btnList1"
              class="btn-list"
              @click="gotoList()"
            >
              목록으로
            </button>
          </div>

          <div class="tb-pn-view-type">
            <table>
              <colgroup>
                <col style="width: 180px">
                <col style="width: auto">
                <col style="width: 210px">
              </colgroup>
              <tbody>
                <tr
                  v-if="notice.nextItem"
                  @click="noticeClick(notice.nextItem)"
                >
                  <th>다음글</th>
                  <td>
                    <p>
                      <a href="#">{{ notice.nextItem.title }}</a>
                    </p>
                  </td>
                  <td>{{ notice.nextItem.createDateStr }}</td>
                </tr>
                <tr
                  v-if="notice.prevItem"
                  @click="noticeClick(notice.prevItem)"
                >
                  <th>이전글</th>
                  <td>
                    <p>
                      <a href="#">{{ notice.prevItem.title }}</a>
                    </p>
                  </td>
                  <td>{{ notice.prevItem.createDateStr }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <comment-form
            v-show="notice.allowComments"
            ref="commentForm"
            :content-type="6"
          />
        </div>
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </article>
</template>

<script>
import axios from '@axios'
import Pagination from '@/components/controls/Pagination.vue'
import { callExternalApi } from '@/core/global/global-liveinfo'
import { mapGetters } from 'vuex'
import { setStatistics } from '@/common/statistics/service'

import CommentForm from '../CommentForm.vue'

export default {
  components: {
    Pagination,
    CommentForm,
  },
  data() {
    return {
      notices: [],
      notice: {
        prevNotice: {
          title: '',
          createDateStr: '',
        },
        nextNotice: {
          title: '',
          createDateStr: '',
        },
      },
      query: {
        keyword: '',
        searchType: 1,
        page: 1,
      },
      codes: {
        searchType: [],
      },
      currentPage: 1,
      totalRecord: 0,
      pageViewCount: 12,
      noticeFiles: [],
      commentlist: [],
      comment: {
        commentId: 0,
        commentText: '',
        contentType: 6,
        contentId: 0,
        userId: 0,
        isEditing: false,
        editText: '',
        isReplyEditing: false,
        parentId: 0,
        replyEditText: '',
        isReplyModify: false,
        replyModifyText: '',
        isUserDelete: false,
      },
      commentId: null,
      contentType: 6,
      entry: null,
    }
  },
  computed: {
    ...mapGetters({
      params: 'util/getParams',
      infoSetting: 'infoData/getInfoSetting',
    })
  },
  watch: {
    params() {
      if (this.params && this.params.route === this.$route.name) this.fetchNotice(this.params.id, this.params.entry)
    },
    $route() {
      if (this.$route.query && this.$route.query.id) this.fetchNotice(this.$route.query.id, this.$route.query.entry)
    },
    currentPage() {
      this.fetchNotices()
    },
    infoSetting() {
      if (this.$route.query.id) this.fetchNotice(this.$route.query.id, this.$route.query.entry)
      else if (this.$route.params.id) this.fetchNotice(this.$route.params.id, this.$route.params.entry)
      else if (this.params) this.fetchNotice(this.params.id, this.params.entry)
    }
  },
  mounted() {
    this.fetchCodes()
    this.fetchNotices()

    if (this.infoSetting) {
      if (this.$route.query.id) this.fetchNotice(this.$route.query.id, this.$route.query.entry)
      else if (this.$route.params.id) this.fetchNotice(this.$route.params.id, this.$route.params.entry)
      else if (this.params) this.fetchNotice(this.params.id, this.params.entry)
    }
  },
  methods: {
    fetchCodes() {
      axios
        .get('/fu/notice/codes')
        .then((rs) => {
          const { searchType } = rs.data
          this.codes.searchType = searchType
        })
        .catch(() => {
          this.showAlertDialog(
            '조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'
          )
        })
    },
    fetchNotices() {
      axios.post('/fu/notice/list', {
        search: {
          query: this.query,
        },
        // sort: {
        //   predicate: 'IsFixed',
        //   reverse: true,
        // },
        pagination: {
          number: this.currentPage,
          count: this.pageViewCount,
        },
      })
        .then((rs) => {
          const { items, totalRecord } = rs.data

          this.notices = items
          this.totalRecord = totalRecord
        })
        .catch(() => {
          this.showAlertDialog(
            '조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'
          )
        })
    },
    pageUpdate(page) {
      this.currentPage = page
    },
    scrollTop() {
      const detailWrap = document.querySelectorAll(".sub-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: 0 })
      })
    },
    updateViewCount(id) {
      axios.patch(`/fu/notice/UpdateViewCount/${id}`).catch(() => {
        this.showAlertDialog('조회수 업데이트에 실패하였습니다.')
      })
    },
    noticeClick(item) {
      this.fetchNotice(item.id, 211) // 211: 서브페이지 진입
    },
    fetchNotice(id, entry = 212) {
      this.updateViewCount(id)
      // 공지사항 클릭 통계
      if (!this.entry) this.entry = Number(entry)
      setStatistics(this.entry, id)

      // 공지 리스트 비활성화
      document.querySelector('#noticeView').style.display = 'block'
      // 상세 페이지 활성화
      document.querySelector('.search-wrap').style.display = 'none'
      document.querySelector('.tb-list-type').style.display = 'none'
      document.querySelector('.pg-wrap').style.display = 'none'

      axios
        .get(`/fu/notice/${id}`)
        .then((rs) => {
          this.notice = rs.data

          this.scrollTop()
        })
        .catch(() => {
          this.showAlertDialog(
            '조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'
          )
        })
      this.$refs.commentForm.fetchComment(id, this.contentType)
      this.fetchNoticeFiles(id)

      if (this.params) this.$store.commit('util/setParams', null)
    },
    fetchNoticeFiles(id) {
      axios.get(`/fu/notice/${id}/files`)
        .then(rs => {
          this.noticeFiles = rs.data
        })
        .catch(() => {
          this.showAlertDialog(
            '첨부 파일조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'
          )
        })
    },
    downloadFile(id, fileName) {
      axios.get(`/fu/attachfile/${id}`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])

          // const name = rs.headers["content-disposition"]
          //   .split("filename=")[1]
          //   .replace(/"/g, "")

          const reader = new FileReader()

          reader.readAsDataURL(blob)

          reader.onloadend = () => {
            const content = reader.result

            callExternalApi("FileDownload", JSON.stringify({ content: content.split(',')[1], fileName }))
          }
        })
        .catch(() => {
        })
    },
    gotoList() {
      document.querySelector('#noticeView').style.display = 'none'
      document.querySelector('.search-wrap').style.display = 'flex'
      document.querySelector('.tb-list-type').style.display = 'block'
      document.querySelector('.pg-wrap').style.display = 'flex'
    },
  },
}
</script>

<style>
#content img {
  max-width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: separate;
}

table thead th {
  position: sticky;
  z-index: 9;
  top: 0;
}
</style>
